<script>
import { ContentLoader } from 'vue-content-loader'
import { computed } from 'vue'

export default {
  components: { ContentLoader },
  setup() {
    const isDarkSkin = computed(ctx => ctx.$store.state.appConfig.layout.skin === 'dark')
    const primaryColor = computed(() => (isDarkSkin.value ? '#283046' : '#eaeced'))
    const secondaryColor = computed(() => (isDarkSkin.value ? '#435178' : '#ffffff'))

    return {
      primaryColor,
      secondaryColor,
    }
  },
}
</script>

<template>
  <content-loader
    width="1200"
    height="900"
    :view-box="`0 0 1200 900`"
    :speed="2"
    :primary-color="primaryColor"
    :secondary-color="secondaryColor"
  >
    <rect x="20" y="20" rx="0" ry="0" width="750" height="460" />
    <rect x="20" y="500" rx="0" ry="0" width="365" height="250" />
    <rect x="405" y="500" rx="0" ry="0" width="365" height="250" />

    <rect x="840" y="20" rx="0" ry="0" width="350" height="300" />
    <rect x="840" y="340" rx="0" ry="0" width="350" height="150" />
  </content-loader>
</template>
