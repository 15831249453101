<template>
  <b-sidebar
    :id="id"
    ref="sidebar"
    :visible="isSidebarActive"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >

    <template #default="{ hide }">
      <b-overlay
        :show="isLoading"
        rounded="sm"
        variant="secondary"
        opacity="0.75"
      >
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="itemId || itemData.id"
            class="mb-0"
          > <span v-t="`modules.product_categories.pages.edit`" /> {{ itemData.name[getLocale()] || itemData.name.en || `#${itemId}` }}</h5>
          <h5
            v-else
            v-t="`modules.product_categories.pages.add`"
            class="mb-0"
          />

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Body -->
          <b-form
            class="p-2"
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
          >
            <div class="mb-2">
              <div>
                <b-form-checkbox
                  v-model="itemData.is_sub_category"
                  :value="true"
                >
                  {{ $t('modules.product_categories.inputs.is_sub_category') }}
                </b-form-checkbox>
              </div>
            </div>
            <Transition name="fade-bottom">
              <div
                v-if="itemData.is_sub_category"
                class="mb-2"
              >
                <i-input
                  id="parentCategory"
                  v-model="itemData.product_category_id"
                  :errors="validationErrors.product_category_id"
                  type="select"
                  :options="parentOptions.filter(cat => cat.id !== itemId)"
                  :reduce="cat => cat.id"
                  title="modules.product_categories.inputs.parent"
                />
              </div>
            </Transition>

            <div class="mb-2">
              <i-input
                id="itemName"
                ref="itemName"
                v-model="itemData.name"
                :errors="validationErrors.name"
                type="text"
                title="inputs.name"
                :validation-rules="['required']"
                :translatable="true"
              />
            </div>

            <div class="mb-2">
              <i-input
                v-model="itemData.description"
                :errors="validationErrors.description"
                id="itemDescription"
                ref="itemDescription"
                type="textarea"
                title="inputs.description"
                placeholder="modules.product_categories.inputs.description:placeholder"
                rows="2"
                :translatable="true"
              />
            </div>

            <div>
              <i-input
                v-model="itemData.is_active"
                :errors="validationErrors.is_active"
                type="switch"
                title="inputs.is_active"
                description="inputs.is_active:description"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </i-input>
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-t="(itemId || itemData.id)?`Edit`:`Add`"
                variant="primary"
                class="mr-2"
                type="submit"
              />
              <b-button
                v-t="`Cancel`"
                variant="outline-secondary"
                @click="hide"
              />
            </div>
          </b-form>
        </validation-observer>

      </b-overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BButton, BOverlay, BFormCheckbox,
} from 'bootstrap-vue'
import { onMounted, onUnmounted, ref } from 'vue'
import { ValidationObserver } from 'vee-validate'
import store from '@/store'
import productCategoryStoreModule from '@/views/models/product_categories/productCategoryStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { getLocale } from '@core/utils/utils'

export default {
  components: {
    BFormCheckbox,
    ValidationObserver,
    BSidebar,
    BForm,
    BButton,
    BOverlay,
  },
  directives: {
    //
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      default: 'sidebar-add-new-category',
      required: false,
    },
    itemId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    itemId(newValue) {
      if (newValue) {
        this.isLoading = true
        this.fetchItem(newValue).finally(() => {
          this.isLoading = false
        })
      } else {
        this.resetForm()
      }
    },
    isSidebarActive(newValue) {
      if (newValue) {
        this.fetchOptions()
        if (!this.itemId) {
          this.resetForm()
        }
      } else {
        this.resetForm()
      }
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: { getLocale },
  emits: ['refetch-data'],
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'product_categories'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, productCategoryStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, productCategoryStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Data definition
    const refs = ref({})
    const emptyItemData = {
      product_category_id: null,
      name: {},
      description: {},
      is_sub_category: false,
      is_active: true,
    }
    const itemData = ref({})
    const isLoading = ref(true)
    const parentOptions = ref([])

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(emptyItemData))
      isLoading.value = false
      ctx.emit('update:item-id', null)
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const { validationErrors, fetch, save } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      () => {
        ctx.emit('refetch-data')
        ctx.emit('update:is-sidebar-active', false)
      },
      null,
      null,
      () => {
        // refs.value.value.sidebar.hide()
        itemData.value = JSON.parse(JSON.stringify(emptyItemData))

        ctx.emit('update:is-sidebar-active', false)
      },
    )

    const fetchItem = () => {
      if (itemData.value.id !== props.itemId) {
        itemData.value.id = props.itemId
        return fetch()
      }
      return null
    }

    const onSubmit = () => {
      isLoading.value = true
      save(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    const fetchOptions = () => {
      store.dispatch(`${STORE_MODULE_NAME}/fetchList`, { product_category_id: 'null' }).then(response => {
        if (response.data.success) {
          parentOptions.value = response.data.data
        }
      })
    }

    return {
      itemData,
      parentOptions,
      fetchItem,
      validationErrors,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      fetchOptions,

      refs,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
