<template>
  <div>
    <product-details-loader
      v-if="isLoading"
    />
    <section
      v-else
      class="invoice-add-wrapper"
    >
      <b-row class="invoice-add">

        <!-- Col: Left (Main Container) -->
        <b-col
          cols="12"
          xl="8"
          md="8"
        >
          <validation-observer ref="refFormObserver">
            <b-form @submit.prevent>
              <b-row class="match-height">

                <b-col cols="12">
                  <b-card :title="$t('cards.productInformation')">
                    <b-card-body>
                      <b-row class="mb-1">
                        <b-col md="8">
                          <i-input
                            id="itemName"
                            ref="itemName"
                            v-model="itemData.name"
                            :errors="validationErrors.name"
                            type="text"
                            title="inputs.product_name"
                            required
                            :translatable="true"
                          />
                        </b-col>
                        <b-col md="4">
                          <i-input
                            v-model="itemData.categories"
                            :errors="validationErrors.category"
                            type="select"
                            title="inputs.category"
                            :options="categoryOptions"
                            :clearable="true"
                            :nested-options="true"
                            :multiple="true"
                            @option:selecting="filterSelectedCategories"
                            @option:selected="applyFilteredSelectedCategories"
                          >
                            <template #list-header>
                              <li
                                v-b-toggle.sidebar-add-new-category
                                class="add-new-client-header d-flex align-items-center my-50"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  size="16"
                                />
                                <span
                                  v-t="`modules.product_categories.actions.add_new`"
                                  class="align-middle ml-25"
                                />
                              </li>
                            </template>
                          </i-input>
                        </b-col>
                      </b-row>
                      <b-row class="mb-1">
                        <b-col md="12">
                          <i-input
                            v-model="itemData.short_description"
                            :errors="validationErrors.short_description"
                            type="textarea"
                            title="inputs.short_description"
                            placeholder="inputs.short_description:placeholder"
                            rows="2"
                            :translatable="true"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="12">
                          <i-input
                            v-model="itemData.description"
                            :errors="validationErrors.description"
                            type="editor"
                            title="inputs.description"
                            placeholder="inputs.description:placeholder"
                            rows="5"
                            :translatable="true"
                          />
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-col>
                <b-col cols="12">
                  <b-card>
                    <b-card-body class="py-0 d-flex">
                      <b-card-header class="mr-2 py-0">
                        {{ $t('modules.products.inputs.product_type') }}:
                      </b-card-header>

                      <b-form-radio
                        v-model="itemData.type"
                        name="product-type-radio"
                        value="basic"
                        class="custom-control-primary mr-1"
                      >
                        {{ $t('modules.products.inputs.product_type_options.basic') }}
                      </b-form-radio>

                      <b-form-radio
                        v-model="itemData.type"
                        name="product-type-radio"
                        value="variations"
                        class="custom-control-info"
                      >
                        {{ $t('modules.products.inputs.product_type_options.multi') }}
                      </b-form-radio>
                    </b-card-body>
                  </b-card>

                </b-col>

                <b-col
                  cols="6"
                >
                  <b-card
                    v-if="itemData.type === 'basic'"
                    :title="$t('cards.price')"
                  >
                    <b-card-body>
                      <b-row>
                        <b-col md="12">
                          <i-input
                            v-model="itemData.price"
                            :errors="validationErrors.price"
                            type="price"
                            title="inputs.regular_price"
                            required
                            min="0.01"
                            :currency="itemData.currency_code"
                            @formatted-value-changed="(value) => {itemData.price_text=value}"
                          >
                            <!--                            <template #append>-->
                            <!--                              <v-select-->
                            <!--                                v-model="itemData.currency_code"-->
                            <!--                                :errors="validationErrors.currency_code"-->
                            <!--                                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"-->
                            <!--                                :options="currencyOptions"-->
                            <!--                                input-id="currency_code"-->
                            <!--                                placeholder="inputs.currency:placeholder"-->
                            <!--                                :clearable="false"-->
                            <!--                                :class="{'is-invalid' : validationErrors.currency_code && validationErrors.currency_code.length>0}"-->
                            <!--                              />-->
                            <!--                            </template>-->
                          </i-input>
                        </b-col>
                        <b-col md="12">
                          <i-input
                            v-model="itemData.sale_price"
                            :errors="validationErrors.sale_price"
                            type="price"
                            title="inputs.sale_price"
                            min="0.01"
                            :validation-rules="['between:0.01,' + (itemData.price - 0.01)]"
                            :currency="itemData.currency_code"
                            description="inputs.sale_price:description"
                            @formatted-value-changed="(value) => {itemData.sale_price_text=value}"
                          />
                        </b-col>
                        <b-col md="12">
                          <i-input
                            v-model="itemData.cost_price"
                            :errors="validationErrors.cost_price"
                            type="price"
                            title="inputs.cost_price"
                            min="0"
                            :validation-rules="['max_value:' + (itemData.price - 0.01)]"
                            :currency="itemData.currency_code"
                            description="inputs.cost_price:description"
                            description-icon="EyeOffIcon"
                            muted-text="Used to show statistics for your performance"
                          />
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-col>
                <b-col
                  cols="6"
                >
                  <b-card
                    v-if="itemData.type === 'basic'"
                    :title="$t('cards.stock')"
                  >
                    <b-card-body>
                      <b-row>
                        <b-col md="6">
                          <i-input
                            v-model="itemData.has_unlimited_qty"
                            :errors="validationErrors.has_unlimited_qty"
                            type="switch"
                            title="inputs.has_unlimited_qty"
                            description="inputs.has_unlimited_qty:description"
                          >
                            <span class="switch-icon-left">
                              <feather-icon icon="CheckIcon" />
                            </span>
                            <span class="switch-icon-right">
                              <feather-icon icon="XIcon" />
                            </span>
                          </i-input>
                        </b-col>
                        <b-col md="6">
                          <i-input
                            v-model="itemData.qty"
                            :errors="validationErrors.has_unlimited_qty"
                            type="number"
                            title="inputs.qty"
                            min="0"
                            :disabled="itemData.has_unlimited_qty"
                            :required="!itemData.has_unlimited_qty"
                          />
                        </b-col>
                        <b-col md="12">
                          <i-input
                            v-model="itemData.weight_amount"
                            :errors="validationErrors.weight_amount"
                            type="number"
                            title="inputs.weight_amount"
                            label="inputs.weight"
                            min="0"
                            step="0.01"
                            :validation-rules="['min_value:0']"
                            :input-container-bindings="{
                              style: 'width: 60%'
                            }"
                          >
                            <template #append>
                              <v-select
                                v-model="itemData.weight_unit"
                                :errors="validationErrors.weight_unit"
                                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                :options="['G','KG','Pound']"
                                input-id="weight_unit"
                                placeholder="inputs.weight_unit:placeholder"
                                :clearable="false"
                                :class="{'is-invalid' : validationErrors.weight_unit && validationErrors.weight_unit.length>0}"
                              />
                            </template>
                          </i-input>
                        </b-col>
                        <b-col md="12">
                          <i-input
                            v-model="itemData.sku"
                            :errors="validationErrors.sku"
                            type="text"
                            title="inputs.sku"
                            :value="itemData.sku"
                          />
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-col>
                <b-col cols="12">
                  <b-card :title="$t('cards.images')">
                    <!-- Items Section -->
                    <b-card-body>
                      <i-input
                        v-model="itemData.images_files"
                        :errors="validationErrors.images"
                        type="images"
                        title="inputs.images"
                        label=" "
                        description="inputs.images:description"
                        @change="val => itemData.images = val"
                      />
                    </b-card-body>

                  </b-card>
                </b-col>
                <!-- Product Options -->
                <b-col
                  v-if="itemData.type === 'variations'"
                  cols="12"
                >
                  <product-options-card
                    ref="productOptionsCard"
                    v-model="itemData"
                    :options.sync="itemData.options"
                    :products.sync="itemData.products"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="4"
          class="invoice-actions mt-md-0 mt-2"
        >

          <!-- Preview -->
          <b-tabs
            align="right"
            pills
            class="translatable-card"
            nav-wrapper-class="nav-wrapper"
          >
            <template v-for="(language, langIndex) in getLanguagesList()">
              <b-tab
                :key="'productPreview_' + language.slug"
                :active="langIndex===0"
                :title="$t(language.name)"
              >
                <product-preview
                  v-if="itemData"
                  :item="itemData"
                  :language-slug="language.slug"
                  :url-base="productsUrlBase"
                />
              </b-tab>
            </template>
          </b-tabs>
          <modal-edit-seo
            :value="itemData.meta"
            :reference-value="{title: itemData.name, description: itemData.short_description}"
            :url-base="productsUrlBase"
            @submit="v => itemData.meta = v"
            @update:value="v => itemData.meta = v"
          />

          <div class="position-sticky">

            <!-- Action Buttons -->
            <b-card>
              <!-- Button: Publish -->
              <b-button
                v-t="`Save and Exit`"
                variant="primary"
                class="mb-75"
                block
                @click="save(1)"
              />
              <!--          :disabled="false"-->

              <!-- Button: Save -->
              <b-button
                v-t="`Save`"
                variant="outline-primary"
                block
                @click="save(0)"
              />

              <!--          &lt;!&ndash; Button: Preview &ndash;&gt;-->
              <!--          <b-button-->
              <!--            v-t="`Preview`"-->
              <!--            variant="outline-primary"-->
              <!--            class="mb-75"-->
              <!--            block-->
              <!--          />-->

              <!-- Button: Back -->
              <b-button
                v-t="`Exit`"
                variant="outline-secondary"
                class="mb-75"
                block
                @click="exit"
              />
            </b-card>

            <hr>
            <div class="mt-2">

              <!-- Is Published Free -->
              <div
                class="d-flex justify-content-between align-items-center my-1"
              >
                <label
                  v-t="`inputs.is_published`"
                  for="is_published"
                >??</label>
                <b-form-checkbox
                  id="is_published"
                  v-model="itemData.is_published"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="EyeIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="EyeOffIcon" />
                  </span>
                </b-form-checkbox>
              </div>

              <!-- Tax Free -->
              <div
                class="d-flex justify-content-between align-items-center my-1"
              >
                <label
                  v-t="`inputs.is_tax_free`"
                  for="is_tax_free"
                >Tax Free</label>
                <b-form-checkbox
                  id="is_tax_free"
                  v-model="itemData.is_tax_free"
                  switch
                />
              </div>

              <!-- Can be shipped -->
              <div class="d-flex justify-content-between align-items-center">
                <label
                  for="requires_shipping"
                >
                  {{ $t(`inputs.requires_shipping`) }}
                  <feather-icon
                    v-b-tooltip.hover
                    class="ml-25 text-info"
                    icon="InfoIcon"
                    size="20"
                    :title="$t('inputs.requires_shipping:description')"
                  />
                </label>
                <b-form-checkbox
                  id="requires_shipping"
                  v-model="itemData.requires_shipping"
                  switch
                />
              </div>

              <!-- Free Shipping -->
              <!--              <div-->
              <!--                v-if="itemData.requires_shipping"-->
              <!--                class="d-flex justify-content-between align-items-center my-1"-->
              <!--              >-->
              <!--                <label-->
              <!--                  v-t="`inputs.has_free_shipping`"-->
              <!--                  for="has_free_shipping"-->
              <!--                >Free Shipping</label>-->
              <!--                <b-form-checkbox-->
              <!--                  id="has_free_shipping"-->
              <!--                  v-model="itemData.has_free_shipping"-->
              <!--                  switch-->
              <!--                />-->
              <!--              </div>-->
            </div>
          </div>
        </b-col>
      </b-row>
      <sidebar-add-new-category
        :is-sidebar-active.sync="isAddNewCategorySidebarActive"
        @refetch-data="fetchCategories"
      />
    </section>
  </div>
</template>

<script>
import {
  ref, onUnmounted, computed,
} from 'vue'
import { heightTransition } from '@core/mixins/ui/transition'
import router from '@/router'
import store from '@/store'
import {
  BRow,
  BCol,
  BTabs,
  BTab,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormCheckbox,
  VBToggle, BFormRadio, BCardHeader, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
import ProductPreview from '@/views/models/products/add/ProductPreview.vue'
import { getLanguagesList, getLocaleCurrency, shop } from '@core/utils/utils'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import ProductDetailsLoader from '@/views/loaders/ProductDetailsLoader.vue'
import ProductOptionsCard from '@/views/models/products/add/ProductOptionsCard.vue'
import ModalEditSeo from '@/views/models/products/add/ModalEditSeo.vue'
import productStoreModule from '../productStoreModule'
import SidebarAddNewCategory from '../../product_categories/add/SidebarAddNewCategory.vue'

export default {
  components: {
    ModalEditSeo,
    ProductOptionsCard,
    BCardHeader,
    ProductDetailsLoader,
    ProductPreview,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormCheckbox,
    BFormRadio,
    vSelect,
    SidebarAddNewCategory,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  computed: {
    //
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs

    if (this.itemData.id) {
      this.fetchItemData()
    }
  },
  created() {
    // window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    // window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getLanguagesList,
  },
  setup() {
    const STORE_MODULE_NAME = 'products'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Data definition
    const refs = ref({})
    const productOptionsCard = ref()
    const currencyOptions = ref(['EGP', 'SAR', 'USD'])
    const itemData = ref({
      id: router.currentRoute.params.id || null,
      uuid: null,
      name: {
        // en: 'Initial product name in english',
        // ar: 'Initial product name in arabic',
        en: null,
        ar: null,
      },
      category: null,
      categories: null,
      short_description: {
        // en: 'This is example of short description',
        // ar: 'This is example of short description',
        en: null,
        ar: null,
      },
      description: {
        // en: '<h1 class="ql-align-center">Example of full description text</h1>',
        // ar: '<h1 class="ql-align-center">Example of full description text</h1>',
        en: null,
        ar: null,
      },

      type: 'basic', // ['basic', 'variations']

      weight_amount: null,
      weight_unit: 'G',

      price: null,
      currency_code: getLocaleCurrency(),
      price_text: null,
      sale_price: null,
      sale_price_text: null,
      cost_price: null,

      sku: null,
      has_unlimited_qty: true,
      qty: null,

      is_published: true,
      requires_shipping: true,
      has_free_shipping: false,
      is_tax_free: false,

      images: [],
      images_files: [],

      // ? Set single Item in form for adding data
      options: [],
      products: [],

      meta: {
        slug: null,
        auto_slug: null,
        title: {
          en: null,
          ar: null,
        },
        description: {
          en: null,
          ar: null,
        },
        keywords: [],
      },

      published: true,
    })
    const isLoading = computed(() => (itemData.value.id && !itemData.value.uuid))
    const categoryOptions = ref([])
    const isAddNewCategorySidebarActive = ref(false)

    const fetchCategories = () => {
      store.dispatch('products/fetchCategories')
        .then(response => { categoryOptions.value = response.data.data })
    }
    fetchCategories()

    const {
      validationErrors,
      fetch,
      save,
      exit,
    } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      itemData,
      data => {
        itemData.value.images_files = data.images // TODO: Check if is effective
        itemData.value = {
          ...itemData.value,
          ...data,
        }
        if (productOptionsCard.value) {
          productOptionsCard.value.reflectParentUpdatesToLocal(itemData.value)
        }
      },
      'products.edit',
      'products.list',
    )

    const filteredSelectedCategories = ref(null)
    const filterSelectedCategories = recentlySelectedCategory => {
      if (recentlySelectedCategory.parent) {
        filteredSelectedCategories.value = itemData.value.categories?.filter(c => c.id !== recentlySelectedCategory.parent) || []
      } else {
        filteredSelectedCategories.value = itemData.value.categories?.filter(c => c.parent !== recentlySelectedCategory.id) || []
      }
      filteredSelectedCategories.value.push(recentlySelectedCategory)
    }

    const applyFilteredSelectedCategories = () => {
      if (!filteredSelectedCategories.value) {
        return
      }
      itemData.value.categories = filteredSelectedCategories.value
      filteredSelectedCategories.value = null
    }

    const productsUrlBase = `${shop().url}product/`

    return {
      isLoading,
      refs,
      productOptionsCard,
      itemData,
      currencyOptions,
      categoryOptions,
      validationErrors,
      productsUrlBase,
      filterSelectedCategories,
      applyFilteredSelectedCategories,
      fetchCategories,
      fetchItemData: fetch,
      isAddNewCategorySidebarActive,
      save: shouldExist => {
        if (productOptionsCard.value) {
          productOptionsCard.value.reflectLocalUpdatesToParent()
        }
        save(shouldExist)
      },
      exit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.position-sticky {
  top: 100px;
}
</style>
