<template>
  <b-modal
    id="modal-edit-product-seo"
    ok-only
    :ok-title="$t('Save')"
    centered
    :title="$t('modules.products._cards.seo.title')"
    @show="reset"
    @ok="resetMirrorFlags(); $emit('submit', localValue)"
  >
    <validation-observer
      ref="refFormObserver"
    >
      <b-form @submit.stop.prevent="onFormSubmit">
        <b-row class="mb-1">
          <b-col md="12">
            <i-input
              id="itemName"
              ref="itemName"
              v-model="localValue.title"
              :errors="validationErrors.meta ? validationErrors.meta.title : []"
              type="text"
              title="modules.products._cards.seo.inputs.meta_title"
              placeholder=""
              :translatable="true"
              :description-callback="seoTitleCallback"
            />
          </b-col>
          <b-col md="12">
            <i-input
              v-model="localValue.description"
              :errors="validationErrors.meta ? validationErrors.meta.description : []"
              type="textarea"
              title="modules.products._cards.seo.inputs.meta_description"
              placeholder=""
              rows="2"
              :translatable="true"
              :description-callback="seoDescriptionCallback"
            />
          </b-col>
          <b-col md="12">
            <validation-provider
              #default="{ errors }"
              :name="$t(`modules.products._cards.seo.inputs.page_link`)"
              rules="required"
            >
              <b-form-group
                :label="$t(`modules.products._cards.seo.inputs.page_link`)"
                label-for="page_link`"
              >
                <b-input-group>
                  <b-form-input
                    id="page_link"
                    v-model="localValue.slug"
                    type="text"
                    minlength="3"
                    maxlength="100"
                    required
                    :state="validationErrors.meta && validationErrors.meta.slug ? false : null"
                  />
                  <template slot="append">
                    <b-input-group-append>
                      <b-input-group-text class="ltr bg-light font-small-2">
                        {{ urlBase }}
                      </b-input-group-text>
                    </b-input-group-append>
                  </template>
                </b-input-group>

                <small class="text-danger">{{ validationErrors.meta ? validationErrors.meta.slug : null }}</small>
              </b-form-group>

            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BCol,
  BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupText, BModal, BRow,
} from 'bootstrap-vue'

import {
  computed,
  ref,
} from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BRow,
    BInputGroupText,
    ValidationProvider,
    BInputGroupAppend,
    BInputGroup,
    BCol,
    ValidationObserver,
    BFormInput,
    BModal,
    BFormGroup,
    // BSV
    BForm,

  },
  props: {
    value: {
      type: Object,
      default: Object,
    },
    referenceValue: {
      type: Object,
      default: Object,
    },
    validationErrors: {
      type: Object,
      default: Object,
    },
    urlBase: {
      type: String,
      default: String,
    },
  },
  watch: {
    value: {
      handler() {
        this.resetMirrorFlags()
        this.handleReferenceValueUpdate(this.referenceValue)
      },
      deep: true,
    },
    referenceValue: {
      handler(newValue) {
        this.handleReferenceValueUpdate(newValue)
        this.$emit('update:value', this.localValue)
      },
      deep: true,
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  setup(props) {
    const localValue = ref({
      title: {},
      description: {},
      slug: null,
    })

    const isLoading = ref(false)
    const isSeoTitleEnMirrorToReference = ref(false)
    const isSeoTitleArMirrorToReference = ref(false)
    const isSeoDescriptionEnMirrorToReference = ref(false)
    const isSeoDescriptionArMirrorToReference = ref(false)
    const refs = ref({})

    const reset = () => {
      localValue.value = JSON.parse(JSON.stringify(props.value))
    }
    reset()

    const handleReferenceValueUpdate = newValue => {
      if (localValue.value.title === null) {
        localValue.value.title = {
          en: null,
          ar: null,
        }
      }
      if (localValue.value.description === null) {
        localValue.value.description = {
          en: null,
          ar: null,
        }
      }
      if (isSeoTitleEnMirrorToReference.value) {
        localValue.value.title.en = newValue.title.en
      }
      if (isSeoTitleArMirrorToReference.value) {
        localValue.value.title.ar = newValue.title.ar
      }
      if (isSeoDescriptionEnMirrorToReference.value) {
        localValue.value.description.en = newValue.description.en?.slice(0, 1000)
      }
      if (isSeoDescriptionArMirrorToReference.value) {
        localValue.value.description.ar = newValue.description.ar?.slice(0, 1000)
      }
    }

    const resetMirrorFlags = () => {
      isSeoTitleEnMirrorToReference.value = (!props.value?.title?.en || props.value?.title?.en === props.referenceValue?.title?.en)
      isSeoTitleArMirrorToReference.value = (!props.value?.title?.ar || props.value?.title?.ar === props.referenceValue?.title?.ar)
      isSeoDescriptionEnMirrorToReference.value = (!props.value?.description?.en || props.value?.description?.en === props.referenceValue?.description?.en?.slice(0, 1000))
      isSeoDescriptionArMirrorToReference.value = (!props.value?.description?.ar || props.value?.description?.ar === props.referenceValue?.description?.ar?.slice(0, 1000))
    }

    resetMirrorFlags()

    const onFormSubmit = () => {
      // console.log('submit')
    }

    const countryCityDetails = computed(() => null)
    const seoTitleCallback = lang => {
      const strLen = localValue.value?.title?.[lang.slug]?.length || 0
      return `${strLen}/70`
    }
    const seoDescriptionCallback = lang => {
      const strLen = localValue.value?.description?.[lang.slug]?.length || 0
      return `${strLen}/320`
    }

    // watch(localValue, () => {
    //   console.log('LOC VAL CHANGED')
    //   console.log('test 1', isSeoTitleArMirrorToReference.value)
    //   handleReferenceValueUpdate(props.referenceValue)
    //   console.log('test 2', isSeoTitleArMirrorToReference.value)
    //   resetMirrorFlags()
    //   console.log('test 3', isSeoTitleArMirrorToReference.value)
    //   // ctx.emit('update:value', localValue.value)
    //   console.log('LOC CHANGE REPORTED', {...localValue.value})
    // }, { deep: true })

    return {
      isLoading,
      localValue,
      refs,
      countryCityDetails,
      isSeoTitleEnMirrorToReference,
      isSeoTitleArMirrorToReference,
      isSeoDescriptionEnMirrorToReference,
      isSeoDescriptionArMirrorToReference,
      onFormSubmit,
      seoTitleCallback,
      seoDescriptionCallback,
      resetMirrorFlags,
      handleReferenceValueUpdate,
      reset,
    }
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
