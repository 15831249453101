<template>
  <b-card
    :title="$t('Quick Preview')"
    :sub-title="$t('This is how the product appear to customers')"
    :no-body="false"
  >
    <b-row>
      <!-- Left: Product Image Container -->
      <b-col
        cols="12"
        md="5"
        class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="coverImage"
                :alt="`Image of ${item.name[languageSlug]}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>
          <b-col
            cols="12 p-0 m-0 px-1 row"
          >
            <b-col

              v-for="image in secondaryImages"
              :key="image"
              class="d-flex align-items-center justify-content-center p-25"
              cols="4"
            >
              <b-img
                :src="image"
                :alt="`Image of ${item.name[languageSlug]}`"
                class="product-img"
                fluid
              />
            </b-col>
          </b-col>
        </b-row>
      </b-col>

      <!-- Right: Product Details -->
      <b-col
        cols="12"
        md="7"
      >

        <!-- Product Name -->
        <h4>
          <feather-icon
            v-if="!item.is_published"
            v-b-tooltip.hover.top="$t('message.invisibleItem')"
            icon="EyeOffIcon"
          />
          {{ item.name[languageSlug] | truncate(200) }}

          <b-badge
            v-if="!item.name[languageSlug]"
            pill
            variant="light-secondary"
            style="width: 120px; height: 21px"
          >&nbsp;</b-badge>
        </h4>

        <!-- Product Brand -->
        <b-card-text class="item-company mb-0">
          <span
            v-for="(category,i) in item.categories"
            :key="`${category.id}-${i}`"
            class="company-name text-primary"
          >
            {{ category.label }},
          </span>
        </b-card-text>

        <!-- Price And Ratings -->
        <div class="ecommerce-details-price d-flex flex-wrap mt-1">
          <component
            :is="item.sale_price?`h5`:`h4`"
            class="item-price mr-1"
            :style="{'text-decoration': item.sale_price?'line-through':'none'}"
          >
            <!--            {{ $n(item.price_text, 'currency') }}-->
            <!--                        {{ item.price_text }}-->
            <money
              v-if="item.price"
              :value="item.price"
            />

            <b-badge
              v-else
              pill
              variant="light-secondary"
              style="width: 70px; height: 21px"
            >&nbsp;</b-badge>
          </component>
          <h4
            v-if="item.sale_price"
            class="item-price list-inline pl-1 border-left"
          >
            <!--            {{ $n(item.sale_price_text, 'currency') }}-->
            <!--            {{ item.sale_price_text }}-->
            <money :value="item.sale_price" />
          </h4>
        </div>

        <!-- Availability -->
        <b-card-text>
          <span
            v-if="item.has_unlimited_qty || item.qty>0"
            v-t="`In stock`"
            class="text-success"
          />
          <span
            v-else
            v-t="`Out of stock`"
            class="text-danger"
          />
          <span v-if="!item.has_unlimited_qty && item.qty>0"> - {{ $tc(`x Items Available`, item.qty) }}</span>
        </b-card-text>

        <!-- Product Description -->
        <b-card-text>{{ item.short_description[languageSlug] | truncate(350) }}</b-card-text>

        <!-- Product Meta [eg. Free shipping] -->
        <ul class="product-features list-unstyled">
          <li v-if="item.requires_shipping && item.has_free_shipping">
            <feather-icon icon="ShoppingCartIcon" />
            <span
              v-t="`Free Shipping`"
              class="ml-1"
            />
          </li>
        </ul>

        <!--        <hr>-->

        <!--        &lt;!&ndash; Colors &ndash;&gt;-->
        <!--        <div class="product-color-options">-->
        <!--          <h6>Colors</h6>-->
        <!--          <ul class="list-unstyled mb-0">-->
        <!--            <li-->
        <!--              v-for="color in ['primary', 'success', 'warning', 'danger', 'info']"-->
        <!--              :key="color"-->
        <!--              class="d-inline-block"-->
        <!--            >-->
        <!--              <div-->
        <!--                class="color-option"-->
        <!--                :class="`b-${color}`"-->
        <!--              >-->
        <!--                <div-->
        <!--                  class="filloption"-->
        <!--                  :class="`bg-${color}`"-->
        <!--                />-->
        <!--              </div>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
      >
        <div class="d-block mb-1">
          <span class="text-muted">{{ $t('modules.products._cards.seo.preview_subtitle') }} . </span>
          <a v-b-modal.modal-edit-product-seo>
            <feather-icon icon="EditIcon" />
            {{ $t('Edit') }}
          </a>
        </div>
        <!-- Product Name -->
        <div>
          <feather-icon
            v-if="!item.is_published"
            v-b-tooltip.hover.top="$t('message.invisibleItem')"
            icon="EyeOffIcon"
          />
          <p class="search-results-title" v-if="item.meta.title">
            {{ item.meta.title[languageSlug] | truncate(70) }}
          </p>

          <b-badge
            v-if="!item.name[languageSlug]"
            pill
            variant="light-secondary"
            style="width: 120px; height: 21px"
          >&nbsp;</b-badge>
        </div>

        <!-- Product URL -->
        <p class="search-results-url" v-if="item.meta.slug || item.meta.auto_slug">
          {{ urlBase + (item.meta.slug || item.meta.auto_slug) }}
        </p>

        <!-- Product Description -->
        <p class="search-results-description" v-if="item.meta.description">
          {{ item.meta.description[languageSlug] | truncate(320) }}
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {
  BBadge, BButton,
  BCard,
  BCardBody, BCardSubTitle,
  BCardText,
  BCol,
  BImg,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { storagePath } from '@core/utils/utils'
import SidebarAddNewCategory from '@/views/models/product_categories/add/SidebarAddNewCategory.vue'
import ModalEditSeo from '@/views/models/products/add/ModalEditSeo.vue'

export default {
  components: {
    ModalEditSeo,
    SidebarAddNewCategory,
    BButton,
    BCardSubTitle,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BImg,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    truncate(str, num, suffix = '...') {
      if (!str) {
        return str
      }
      const truncatedString = str.split('').slice(0, num).join('')
      return truncatedString + (truncatedString.length !== str.length ? suffix : '')
    },
  },
  props: {
    item: {
      type: Object,
      default: Object,
    },
    languageSlug: {
      type: String,
      default: 'en',
    },
    urlBase: {
      type: String,
      default: String,
    },
  },
  computed: {
    coverImage() {
      return this.item.images.length ? storagePath(this.item.images[0]) : this.defaultProductImage
    },
    secondaryImages() {
      return this.item.images.length > 1 ? this.item.images.slice(1).map(image => storagePath(image)) : []
    },
  },
  setup() {
    const defaultProductImage = require('@/assets/images/pages/eCommerce/placeholder.webp')
    return {
      defaultProductImage,
    }
  },
}
</script>

<style lang="scss" scoped>
.search-results-title {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: var(--p-space-050);
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: #1a0dab;
}
.search-results-url {
  margin-bottom: var(--p-space-050);
  font-size: .8125rem;
  line-height: var(--p-font-line-height-400);
  word-wrap: break-word;
  color: #006621;
}
.search-results-description {
  font-size: .8125rem;
  line-height: 1.125rem;
  word-wrap: break-word;
  color: #545454;
}

/* Product Options */
.product-color-options {
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
}

.product-color-options {
  .color-option {
    border: 1px solid transparent;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    padding: 3px;

    .filloption {
      height: 18px;
      width: 18px;
      border-radius: 50%;
    }
  }
}
</style>
